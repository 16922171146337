import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import {PERFORMER_IDS} from '../../utils/constants';
import PerformerCard from '../react-performer-card';

import './index.scss';

const PerformerCardList = ({ignorePerformerIds = [PERFORMER_IDS.GIFT_CARD], performers}, {globalRegistry}) => {
  const defaultLocale = globalRegistry?.getDefaultLocale();
  const currentLocale = globalRegistry?.getCurrentLocale();
  const filteredPerformers = useMemo(
    () => performers.filter((performer) => !ignorePerformerIds.includes(performer.id) && performer.images?.length),
    [performers, ignorePerformerIds]
  );

  return (
    <section className="performer-card-container">
      <ul className="performer-card-list">
        {filteredPerformers.map((performer) => {
          return (
            <PerformerCard
              performer={performer}
              key={performer?.id}
              wrapper="li"
              defaultLocale={defaultLocale}
              currentLocale={currentLocale}
            />
          );
        })}
      </ul>
    </section>
  );
};

PerformerCardList.propTypes = {
  performers: PropTypes.array.isRequired,
  ignorePerformerIds: PropTypes.array,
};

PerformerCardList.contextTypes = {
  globalRegistry: PropTypes.object,
};

export default PerformerCardList;
