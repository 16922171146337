import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import {connect, ssr_wait} from '@stubhub/react-store-provider';

import controller from './controller';

import './seo-content.scss';

class SeoBottomContent extends Component {
  constructor(props, context) {
    super(props, context);
    // Initial state for showReadMore set to true
    let showReadMore = true;
    if (typeof props.expanded === 'boolean') {
      showReadMore = !props.expanded;
    }
    this.state = {
      showReadMore,
    };
  }

  @ssr_wait
  init(props) {
    const {seoBottomContentUrl, loadSeoBottomContent} = props;

    return loadSeoBottomContent(seoBottomContentUrl);
  }

  /**
   * Click on Read More will set showReadMore state to false.
   */
  onReadMore = () => {
    this.setState({
      showReadMore: false,
    });
  };

  /**
   * Scroll To Top of the Page
   *
   */
  onBackToTop = () => {
    const {refNode} = this.props;
    /* istanbul ignore next */
    if (refNode && typeof refNode.scrollIntoView === 'function') {
      /* istanbul ignore next */
      refNode.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  };

  /**
   * Scroll to readMore node on click to Read More if readMoreRef is set
   */
  onGoToReadMore = () => {
    const {readMoreRef} = this.props;
    /* istanbul ignore next */
    if (readMoreRef && typeof readMoreRef.scrollIntoView === 'function') {
      /* istanbul ignore next */
      readMoreRef.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  };

  /**
   * React Component Lifecycle.
   *
   */
  UNSAFE_componentWillMount() {
    const {seoBottomContent} = this.props;
    if (!seoBottomContent) {
      this.init(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {seoBottomContentUrl} = this.props;

    /**
     * Reload SEO Bottom Content when `seoBottomContentUrl` is updated.
     */
    if (nextProps.seoBottomContentUrl !== seoBottomContentUrl) {
      this.setState({
        showReadMore: true,
      });

      return this.init(nextProps);
    }
  }

  /**
   * Reset SEO Bottom Content when Component is unmounted.
   */
  componentWillUnmount() {
    const {resetSeoBottomContent} = this.props;
    resetSeoBottomContent && resetSeoBottomContent();
  }

  componentDidMount() {
    const {expanded} = this.props;
    if (expanded) {
      this.onReadMore();
    }
  }

  render() {
    let {seoBottomContent} = this.props;
    const {seoTitle, className, readMoreRef, innerRef} = this.props;
    const {showReadMore} = this.state;
    let seoLinkText;

    if (showReadMore) {
      seoLinkText = (
        <span
          className="SeoBottomContent__readMore SeoBottomContent--link"
          onClick={readMoreRef ? this.onGoToReadMore : this.onReadMore}
        >
          <FormattedMessage id="SeoBottomContent.ReadMore" defaultMessage="Read More" />
        </span>
      );
      seoBottomContent = seoBottomContent && seoBottomContent.slice(0, seoBottomContent.indexOf('</p>'));
    } else {
      seoLinkText = (
        <span className="SeoBottomContent__backToTop SeoBottomContent--link" onClick={this.onBackToTop}>
          <FormattedMessage id="SeoBottomContent.BackToTop" defaultMessage="Back to Top" />
        </span>
      );
    }

    return seoBottomContent ? (
      <section className={className} ref={innerRef}>
        {seoTitle && <h2>{seoTitle}</h2>}
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{__html: seoBottomContent}} />
        {seoLinkText}
      </section>
    ) : null;
  }
}

SeoBottomContent.propTypes = {
  seoBottomContentUrl: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  loadSeoBottomContent: PropTypes.func.isRequired,
  resetSeoBottomContent: PropTypes.func.isRequired,
  innerRef: PropTypes.object,
  refNode: PropTypes.object,
  readMoreRef: PropTypes.node,

  /**
   * SEO title
   */
  seoTitle: PropTypes.string,

  /**
   * The Class Name used to override the Styling for the component.
   *
   */
  className: PropTypes.string,
  expanded: PropTypes.bool,
  seoBottomContent: PropTypes.object,
};

SeoBottomContent.contextTypes = {
  queue: PropTypes.object,
};

const ConnectedSeoBottomContent = connect(controller)(SeoBottomContent);

const ForwardedSeoBottomContent = React.forwardRef((props, ref) => (
  <ConnectedSeoBottomContent innerRef={ref} {...props} />
));
ForwardedSeoBottomContent.displayName = 'ForwardedSeoBottomContent';

export default ForwardedSeoBottomContent;
