import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {FormattedMessage} from 'react-intl';

import ArrowIcon from '@stubhub/react-arrow-icon';

import './index.scss';

const LoadMore = ({className, onclickHandler}) => {
  return (
    <div className={cx('load-more', className)}>
      <a className="load-more__link" onClick={onclickHandler}>
        <FormattedMessage id="LoadMore.text" defaultMessage="See more events" description="Load more text" />
        <ArrowIcon className="load-more__arrow" direction="down" />
      </a>
    </div>
  );
};

LoadMore.propTypes = {
  onclickHandler: PropTypes.func.isRequired,

  /**
   * The Class Name used to override the Styling for the component.
   *
   */
  className: PropTypes.string,
};

export default LoadMore;
