import React from 'react';
import PropTypes from 'prop-types';

import Spinner from '@stubhub/react-spinner';

import PerformerCardList from '../../../modules/react-performer-card-list';

const MainPerformerCards = ({eventData: performers, loading}) => {
  const morePerformers = performers?.length;

  return (
    <>
      <PerformerCardList performers={performers} />
      {loading && <Spinner expand={!morePerformers} />}
    </>
  );
};

MainPerformerCards.propTypes = {
  /**
   * Performers
   */
  eventData: PropTypes.array,

  /**
   * Shows loading spinner if true
   */
  loading: PropTypes.bool,
};

export default MainPerformerCards;
