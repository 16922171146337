import {stringify} from 'qs';
import {createSelector} from 'reselect';

import {selectors as dateFilterSelectors, keys} from '@stubhub/react-date-filter';
import {parseHomeRecoAPIPartnerConfig} from '@stubhub/react-partner-helper';
import {Controller} from '@stubhub/react-store-provider';
import {get} from '@stubhub/rest-method';

import {hideCbtEventsSelector, isCBTModuleEnabledSelector} from '../../../store/gsConfig/FF/selectors';
import {CMA_DEFAULTS, SEARCH_CACHE_MAX_AGE} from '../../../utils/constants';
import DateTimeFormat from '../../../utils/datetimeformat';

function destroy() {
  return (dispatch) => {
    dispatch({type: 'HOME_MAIN_RESET'});
  };
}

const MAX_PERFORMER_CARDS = 18;

function getParams(props, reset) {
  const {eventStartDate, eventEndDate, cookies, dateKey} = props;
  const newProps = {...props};
  newProps.eventStartDate = DateTimeFormat.toAPIString(eventStartDate);
  const endDate = eventEndDate ? new Date(eventEndDate.getTime()) : null;
  if (dateKey === keys.K_CHOOSE && endDate) {
    endDate.setDate(endDate.getDate() + 1);
  }
  newProps.eventEndDate = DateTimeFormat.toAPIString(endDate);
  if (reset) {
    newProps.offset = 0;
  }
  if (cookies) {
    newProps.visitorId = cookies.get('SH_VI');
    newProps.userGuid = cookies.get('track_session_userGUID');
  }

  newProps.limit = MAX_PERFORMER_CARDS;

  return newProps;
}

function getRecoUrl(props, reset, hideCbtEvents) {
  const {point, radius = 50, categoryId, handleName = 'unified_homepage', minAvailableTickets = 1, shstore = 1} = props;
  const {offset, limit, eventStartDate, eventEndDate, visitorId, userGuid} = getParams(props, reset);
  const opts = {
    startIndex: offset,
    point,
    radius,
    handleName,
    minAvailableTickets,
    limit,
    shstore,
    categoryId,
    eventStartDate,
    eventEndDate,
    visitorId,
    userGuid,
    geoExpansion: !hideCbtEvents,
    geoId: hideCbtEvents ? CMA_DEFAULTS.GEO_ID : undefined,
  };

  return `/recommendations/core/v2/eventgroupings?${stringify(opts)}`;
}

function getSearchCatalogUrl(props, reset) {
  const {
    // Point,
    categoryId,
    fieldList = 'id,name,url,imageUrl,images,dateLocal,dateUTC,ticketInfo,displayAttributes,venue',
    minAvailableTickets = 1,
    shstore = 1,
    sort = 'popularity desc',
    profileName = 'reco',
    hidden = false,
    radius = 200,
    status = 'active |contingent',
    units = 'mi',
    eventRows = 3,
    groupType = 'P',
    parking = false,
    mirror = false,
    dateExpansion = true,
    geoExpansion = true,
  } = props;
  const {offset, limit, eventStartDate, eventEndDate, visitorId, userGuid} = getParams(props, reset);
  // TODO: Add "point" back after COVID or when we can change sort to weight on both radius and popularity
  const opts = {
    start: offset,
    /* Point,*/ categoryId,
    fieldList,
    minAvailableTickets,
    eventRows,
    rows: limit,
    shstore,
    sort,
    profileName,
    hidden,
    radius,
    status,
    units,
    groupType,
    parking,
    mirror,
    dateExpansion,
    geoExpansion,
    eventStartDate,
    eventEndDate,
    visitorId,
    userGuid,
  };

  return `/search/catalog/events/v3/eventGroupings?${stringify(opts)}`;
}

function fetchRecoEvents(props = {}, reset) {
  const cache = true;
  const cacheMaxAge = SEARCH_CACHE_MAX_AGE;
  if (!reset && props.pid !== props.categoryId) {
    reset = true;
  }

  return (dispatch, getState) => {
    dispatch({type: 'HOME_MAIN_EVENTS_LOADING', reset});
    const hideCbtEvents = hideCbtEventsSelector(getState());

    return get({
      host: process.env.REACT_APP_API_HOST,
      path: getRecoUrl(props, reset, hideCbtEvents),
      headers: _getFetchRequestHeaders(getState().lang),
      json: true,
      cache,
      cacheMaxAge,
    }).then(
      (body) => {
        dispatchEventLoaded(dispatch, props, reset, body);

        return body;
      },
      () => {
        // eslint-disable-next-line promise/no-nesting
        return get({
          host: process.env.REACT_APP_API_HOST,
          path: getSearchCatalogUrl(props, reset),
          headers: _getFetchRequestHeaders(getState().lang),
          json: true,
          cache,
          cacheMaxAge,
          // eslint-disable-next-line promise/no-nesting
        }).then((body) => {
          dispatchEventLoaded(dispatch, props, reset, body);

          return body;
        });
      },
    );
  };
}

function dispatchEventLoaded(dispatch, props, reset, body) {
  let {groups = []} = body;
  const {totalCount = 0, impressionToken} = body;
  let offset = reset ? 0 : props.offset || 0;
  offset += groups.length;
  const hasMore = offset < totalCount;
  const loading = false;
  let eventData = reset ? [] : props.eventData || [];
  groups = parseHomeRecoAPIPartnerConfig(groups);

  /**
   * Append the Event Data
   *
   */
  eventData = eventData.concat(groups);
  dispatch({
    type: 'HOME_MAIN_EVENTS_LOADED',
    offset,
    hasMore,
    loading,
    eventData,
    recoToken: impressionToken,
    pid: props.categoryId,
  });
}

function _getFetchRequestHeaders(language) {
  const ret = {
    Authorization: process.env.REACT_APP_API_SECRET,
  };
  if (language) {
    ret['Accept-Language'] = language;
  }

  return ret;
}

const userStateSelector = (state) => state.user;
const userLocationSelector = createSelector(userStateSelector, (user) => (user ? user.location : null));
const pointSelector = createSelector(userLocationSelector, (location) =>
  location ? `${location.latitude},${location.longitude}` : null,
);
const eventDataSelector = createSelector(
  (homeState) => homeState.eventData,
  (data) => data || [],
);

const controller = new Controller({
  namespace: 'app.home.main',
  mapStateToProps(state) {
    const homeState = this.getLocalState(state);

    return {
      pid: homeState.pid,
      eventData: eventDataSelector(homeState),
      offset: homeState.offset || 0,
      loading: homeState.loading,
      hasMore: homeState.hasMore !== false, // Default true
      point: pointSelector(state),
      userLocation: userLocationSelector(state),
      eventStartDate: dateFilterSelectors.startDate(state),
      eventEndDate: dateFilterSelectors.endDate(state),
      dateKey: dateFilterSelectors.key(state),
      shstore: state.gsConfig.shstoreId,
      isCBTModuleEnabled: isCBTModuleEnabledSelector(state),
    };
  },
  actionCreators: {
    fetchEvents: fetchRecoEvents,
    destroy,
  },
});

controller.addReducer(
  'HOME_MAIN_EVENTS_LOADING',
  function (state, {reset}) {
    // Make sure the NAMESPACE is used otherwise value will be written into root which could be a wrong place
    const s = {loading: true};
    if (reset) {
      s.offset = 0;
      s.hasMore = false;
      s.eventData = [];
    }

    return this.setLocalState(state, s);
  }.bind(controller),
);

controller.addReducer('HOME_MAIN_EVENTS_LOADED');
controller.addResetReducer(['USER_LOCATION_CHANGE', 'USER_DATE_FILTER_CHANGED', 'HOME_MAIN_RESET']);

export default controller;
